export interface Lang {
    name: string;
    value: string;
}

export const SUPPOER_LOCALES: Lang[] = [
    {
        name: '简体中文',
        value: 'zh_CNS'
    },
    {
        name: '繁體中文',
        value: 'zh_CNT'
    },
    {
        name: 'English',
        value: 'en_US'
    }
];

export const SUPPOER_LOCALES_VALUES = SUPPOER_LOCALES.map(({ value }) => value);
