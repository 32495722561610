import { createActionType, createSyncAction } from '../action.creator';

// Types 定义
const TYPES = createActionType(
    [
        'SET_LOCALE'
    ],
    'LOCALE'
);

export const LOCALE = TYPES;
export const setLocale = createSyncAction(TYPES.SET_LOCALE);
