/* eslint-disable prettier/prettier */
// 用于mts翻译接口key接口传参
const apiLangMapLowerCase = {
    'zh_CNS': 'zh_cns',
    'zh_CNT': 'zh_cnt',
    'en_US': 'en_us'
};

module.exports = {
    apiLangMapLowerCase,
};
