import App from 'next/app';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import Router from 'next/router';
import intl from 'react-intl-universal';
import IntlPolyfill from 'intl';
import NProgress from 'nprogress';

import { IncomingMessage } from 'http';
import { actions } from '@store/actions';
import { ConfigProvider } from 'antd';
import zh_CNS from 'antd/lib/locale/zh_CN';
import zh_CNT from 'antd/lib/locale/zh_TW';
import { getMtsData } from '@utils/cache';
import en_US from 'antd/lib/locale/en_US';
// import CommonApi from '@api/common';
import Mobile from '@utils/mobile';
import { SUPPOER_LOCALES_VALUES } from '../../public/i18n';
import makeStore from '../store';
import '@assets/style/global.less';
import '@assets/style/mobile.less';
import 'nprogress/nprogress.css';
// import withTwindApp from '@twind/next/app';

/* eslint-disable */
// For Node.js, common locales should be added in the application
global.Intl = IntlPolyfill;

const getCurrentLocale = (req: IncomingMessage) => {
    let lang = req
        ? (req as any).currentLocale
        : intl.determineLocale({
            urlLocaleKey: 'lang',
            cookieLocaleKey: 'lang'
        });
    // 默认语言为简体中文
    if (!lang || !SUPPOER_LOCALES_VALUES.includes(lang)) lang = 'zh_CNS';
    return lang;
};

NProgress.configure({
    minimum: 0.3,
    easing: 'ease',
    speed: 500,
    showSpinner: false,
});
Router.events.on('routeChangeStart', () => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const ANTD_LOCALE = { zh_CNS, zh_CNT, en_US };

class MyApp extends App {
    static async getInitialProps({ Component, ctx }) {
        // const fullState: any = ctx.store.getState();

        // 判断是否为移动端
        const isMobile = Mobile.isMobile(ctx.req);

        // 获取域名
        const file_cdn: any = process.env.FILE_CDN || '';

        // 多语言文本设置
        const currentLocale = getCurrentLocale(ctx.req);
        // 获取语言包内容
        // 这里使用 redux 存储当前语言文本，避免在页面切换时客户端重复请求拉取资源。
        // 添加降级处理，在拉取不到 cdn 语言文本时使用本地语言文件。
        // if (!fullState.locale.get(currentLocale)) {
        // const localeData = await CommonApi.getI18Locale(currentLocale)
        // .fetch()
        // .toPromise()
        // .then((r) => {
        //     if (!r) {
        //         throw new Error('local file load error');
        //     }
        //     return r;
        // })
        // .catch((_) => {
        //     return require(`../../public/i18n/${currentLocale}.json`);
        // });
        const localeData = await getMtsData(currentLocale);

        await ctx.store.dispatch(actions.setLocale({ [currentLocale]: localeData }));
        // }

        let pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

        pageProps = { ...pageProps, file_cdn };

        return { pageProps,  currentLocale, isMobile, locale: currentLocale };
    }

    constructor(props: any) {
        super(props);
        const { currentLocale, store } = props;

        // 同构初始化语言包
        // setState 在服务端不会触发，因此服务端使用 ssrIntlInited 作为标识。
        intl.init({
            currentLocale,
            locales: { [currentLocale]: store.getState().locale.get(currentLocale) }
        });
    }

    componentDidMount() {
        // 客户端状态初始化
        // console.log('客户端状态初始化...')
        // this.setState({ intlStatus: IntlInitStatus.CLIENT_UNREADY });
    }

    render() {
        const { Component, pageProps, store, currentLocale, isMobile } = this.props as any;

        // layout 因其情况过多，因此不在根文件 _app 中定义，在各页面中单独引入。
        // store 全局状态可在各页面 getInitialProps 中引用，也可在组件中通过 redux connect 引用。大部分选择使用 connect，防止频繁传值。
        return (
            <ConfigProvider autoInsertSpaceInButton={false} locale={ANTD_LOCALE[currentLocale]}>
                <Provider store={store}>
                    <Component {...pageProps} isMobile={isMobile} />
                </Provider>
            </ConfigProvider>
        );
    }
}

export default withRedux(makeStore)(MyApp);
