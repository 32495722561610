import { ExtendAction } from './action.creator';

type Handler = (state: any, action?: ExtendAction) => any;

interface AsyncHandler {
    init: Handler;
    succeed: Handler;
    failed: Handler;
}

/**
 * 初始化处理
 * @param {string} field
 */
const init = (field: string) => (state: any) => {
    const lastValue = state && state[field] && state[field].value;
    const isObjValue = typeof lastValue === 'object';
    return { ...state, [field]: { loaded: false, value: isObjValue ? { ...lastValue } : lastValue } };
};

/**
 * 请求成功处理
 * @param {string} field
 */
const succeed = (field: string) => (state: any, action?: ExtendAction) => ({
    ...state,
    [field]: {
        loaded: true,
        status: true,
        value: action.payload
    }
});

/**
 * 请求失败处理
 * @param {string} field
 */
const failed = (field: string) => (state: any, action?: ExtendAction) => ({
    ...state,
    [field]: {
        loaded: true,
        status: false,
        value: action.payload
    }
});

/**
 * 创建同步Action Handler
 */
export const createActionHandler = (type: string, hanlder: Handler) => ({
    [type]: hanlder
});

/**
 * 创建异步Action Handler
 */
export const createAsyncActionHandler = (type: string, field: string, handler: AsyncHandler = {} as any) => ({
    [type]: handler.init || init(field),
    [`${type}_SUCCEED`]: handler.succeed || succeed(field),
    [`${type}_FAILED`]: handler.failed || failed(field)
});
