const initialState = {
    file_cdn: '', // 初始化状态
};

const fileCdnReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILE_CDN':
            return { file_cdn: action.payload };
        default:
            return state;
    }
};

  export default fileCdnReducer;
