import { Action } from 'redux';

export interface ExtendAction extends Action {
    payload?: any;
}

/**
 * 创建 Action Type，组合模块名作为 Action Type 的前缀避免命名冲突
 */
export const createActionType = (types: Array<string>, prefix: string) => {
    const res: any = {};
    types.forEach((key: string) => (res[key] = `${prefix}_${key}`));
    return res;
};

/**
 * 创建同步Action
 */
export const createSyncAction = (type: string) => (payload?: any): ExtendAction => ({
    type,
    payload
});

/**
 * 创建异步Action，每一个异步 Action 存在操作成功、操作失败两个结果的对应处理 Action
 */
export const createAsyncAction = (type: string) => ({
    init: (payload?: any): ExtendAction => ({
        type,
        payload
    }),
    succeed: (payload?: any): ExtendAction => ({
        type: `${type}_SUCCEED`,
        payload
    }),
    failed: (payload?: any): ExtendAction => ({
        type: `${type}_FAILED`,
        payload
    })
});
