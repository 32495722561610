class Mobile {
    /**
     * 判断是否是移动端（服务端渲染需要根据请求头判断）
     */
    isMobile = (req: any) => {
        const deviceAgent = req ? req.headers['user-agent'] : navigator.userAgent;
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(deviceAgent);
    };
}

export default new Mobile();
