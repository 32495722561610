import immutable, { Map } from 'immutable';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { rootReducer } from './root';

export default function configureStore(initialState: any) {
    const composeEnhancers = composeWithDevTools({});
    const epicMiddleware = createEpicMiddleware();
    const middlewareEnhancers = applyMiddleware(epicMiddleware);
    // 选择性启用 DevTools
    const enhancers = process.env.NODE_ENV === 'development' ? composeEnhancers(middlewareEnhancers) : middlewareEnhancers;
    let convertedInitialState = initialState;
    // 将服务端获取状态对应的 plain object 转换为 immutable Map
    if (initialState) {
        convertedInitialState = Object.entries(initialState).reduce(
            (result: { [key: string]: immutable.Map<string, any> }, [key, value]: [string, any]) => ({ ...result, [key]: Map(value) }),
            {}
        );
    }
    const store = createStore(rootReducer, convertedInitialState, enhancers);

    return store;
}
