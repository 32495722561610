import immutable, { Map } from 'immutable';
import { createActionHandler } from '../reducer.creator';
import { LOCALE } from '../actions/locale';

interface Action {
    type: string;
    payload?: any;
}

export const initState = Map({});

const handlers = {
    // 设置多语言文本
    ...createActionHandler(LOCALE.SET_LOCALE, (state: immutable.Map<string, any>, action: Action) => {
        const newState = state.merge(action.payload);
        return newState;
    })
};

const locale = (state = initState, action: any = {}) => {
    const fn = handlers[action.type];
    return fn ? fn(state, action) : state;
};

export default locale;
